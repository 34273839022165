import fontHead from './assets/fonts/roboto-condensed.woff2'
import fontMain from './assets/fonts/roboto-regular.woff2'
import fontBeitrag from './assets/fonts/nunito-regular.woff2'
import {createTheme} from "@mui/material/styles"

const fontFamilyMain = '"Roboto", "Helvetica", "Arial", sans-serif'
const fontFamilySecond = '"Roboto Condensed", "Helvetica", "Arial", sans-serif'


const spacing = [0, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048]

const appBarColor = '#009fe3'
const mainPrimaryColor = '#009fe3'
const mainSecondaryColor = '#fff'
const mainErrorColor = '#E31738'

const textPrimaryColor = '#58585a'
const textSecondaryColor = '#cdff00'

const primaryContrastColor = '#FFF'
const secondaryContrastColor = '#0074a5'
const errorContrastColor = '#ffffff'

const paperBackground = '#fff'
const palletBackground = '#f8f8f8'
const pageBackground = '#fff'

const minHeightForTabsAndBars = 64


const theme = createTheme({
	shape: {
		borderRadius: 0
	},
	palette: {
		mode: 'light',
		background: {
			default: pageBackground,
			paper: paperBackground,
		},
		primary: {
			main: mainPrimaryColor,
			contrastText: primaryContrastColor
		},
		secondary: {
			main: mainSecondaryColor,
			contrastText: secondaryContrastColor
		},
		error: {
			main: mainErrorColor,
			contrastText: errorContrastColor
		},
		text: {
			primary: textPrimaryColor,
			secondary: textSecondaryColor,
		}
	},
	typography: {
		fontFamily: fontFamilyMain,
		h1: {
			fontFamily: fontFamilySecond,
			fontSize: '1.8rem',
			textTransform: 'none',
			fontWeight: '400'
		},
		h2: {
			fontFamily: fontFamilySecond,
			fontSize: '1.7rem',
			textTransform: 'none',
			fontWeight: '400'
		},
		h3: {
			fontFamily: fontFamilySecond,
			fontSize: '1.6rem',
			textTransform: 'none',
			fontWeight: '400'
		},
		h4: {
			fontFamily: fontFamilySecond,
			fontSize: '1.5rem',
			textTransform: 'none',
			fontWeight: '400'

		},
		h5: {
			fontFamily: fontFamilySecond,
			fontSize: '1.4rem',
			textTransform: 'none',
			fontWeight: '400'
		},
		h6: {
			fontFamily: fontFamilyMain,
			fontSize: '1.3rem',
			textTransform: 'none',
			fontWeight: '400'
		},
		body1: {
			fontSize: '16px',
			textTransform: 'none'
		},
		body2: {
			fontSize: '.875rem',
			textTransform: 'none'
		}
	},
	spacing: spacing,
	components: {

		MuiAccordion: {
			defaultProps: {
				elevation: 0
			},
			styleOverrides: {
				root: {
					'&:before': {
						backgroundColor: pageBackground
					}
				}
			}
		},
		MuiAppBar: {
			defaultProps: {
				elevation: 0,
				color: 'inherit'
			},
			styleOverrides: {
				root: {
					minHeight: minHeightForTabsAndBars,
					backgroundColor: appBarColor,
					border: 0,
					color: textPrimaryColor,
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					color: primaryContrastColor,
				}
			}
		},
		MuiButton: {
			defaultProps: {
				size: 'large',
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
					fontWeight:'400'

				},
				outlined: {
					fontFamily: fontFamilySecond,
					borderWidth: '2px',
					'&:hover': {
						borderWidth: '2px'
					},
					'&:disabled': {
						borderWidth: '2px'
					}
				},
				outlinedPrimary: {
					fontFamily: fontFamilySecond,
					fontSize: '1.0rem',
					borderWidth: '2px',
					fontWeight: "500",
					borderColor: mainPrimaryColor,
					'&:hover': {
						borderWidth: '2px',
						backgroundColor: mainPrimaryColor,
						color: primaryContrastColor
					}
				},
				outlinedSecondary: {
					fontFamily: fontFamilySecond,
					fontSize: '1.0rem',
					borderWidth: '2px',
					fontWeight: "500",
					borderColor: mainSecondaryColor,
					color: mainSecondaryColor,
					'&:hover': {
						borderWidth: '2px',
						backgroundColor: mainSecondaryColor,
						borderColor: mainSecondaryColor,
						color: mainPrimaryColor
					}
				},
				containedPrimary: {
					fontFamily: fontFamilySecond,
					fontSize: '1.0rem',
					fontWeight: "500",
					borderColor: mainPrimaryColor,
					backgroundColor: mainPrimaryColor,
					color: mainSecondaryColor,
					'&:hover': {
						borderWidth: '2px',
						backgroundColor: textSecondaryColor,
						borderColor: textSecondaryColor,
						color: mainPrimaryColor
					}
				},
				containedSecondary: {
					fontFamily: fontFamilySecond,
					fontSize: '1.0rem',
					fontWeight: "500",
					borderColor: textSecondaryColor,
					color: mainPrimaryColor,
					backgroundColor: textSecondaryColor,
					'&:hover': {
						backgroundColor: mainPrimaryColor,
						borderColor: mainPrimaryColor,
						color: mainSecondaryColor
					}
				},
			}
		},
		MuiButtonGroup: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: {
					fontFamily: fontFamilySecond,
					elevation: 0,
					textTransform: 'none',
				}
			}
		},
		MuiCard: {
			defaultProps: {
				square: true,
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
					@font-face {
					 font-family: 'Roboto Condensed';
					 src: url(${fontHead}) format('woff2');
					 font-style: normal;
					 font-display: swap;
					 font-weight: 400;
					 unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
				  }
		
				  @font-face {
					 font-family: 'RobotoRegular';
					 src: url(${fontMain}) format('woff2');
					 font-style: normal;
					 font-weight: 400;
					 unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
				  }
				   @font-face {
					 font-family: 'Nunito';
					 src: url(${fontBeitrag}) format('woff2');
					 font-style: normal;
					 font-weight: 400;
					 unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
				  }
				`
		},
		MuiFab: {
			styleOverrides: {
				root: {
					boxShadow: '0'
				}
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					alignItems: 'start'
				}
			}
		},

		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: mainPrimaryColor,
					padding: '0 9px 0px 9px'
				}

			}
		},
		MuiIconButton: {
			defaultProps: {
				size: 'small'
			},
		},
		MuiOutlinedInput:  {
			styleOverrides: {
				root: {
					borderRadius: 5,
				}
			}
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
				square: true,
			},
			styleOverrides: {
				outlined: {
					border: '1px solid ' + mainPrimaryColor
				}
			}
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'filled'
			},
		},
		MuiGrid: {
			styleOverrides: {
				root: {
					'& > div.MuiFormControl-root ': {
						width: '100%',
					},
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: '1.3rem',
					fontFamily: fontFamilySecond,
					fontWeight: '400',
					textTransform: 'none',
				}
			}
		},
		MuiTable: {
			defaultProps: {
				size: 'medium'
			}
		},
		MuiTableRow: {
			defaultProps: {
				hover: true
			},
			styleOverrides: {
				root: {
					'&:nth-of-type(odd)': {
						backgroundColor: paperBackground,
					},
					'&:nth-of-type(even)': {
						backgroundColor: palletBackground,
					}
				},
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: '1px solid' + palletBackground,
				},
				head: {
					backgroundColor: paperBackground,
					color: textSecondaryColor,
				},
				stickyHeader: {
					backgroundColor: paperBackground,
					color: textSecondaryColor,
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'filled'
			}
		},

		MuiTypography: {
			styleOverrides: {
				h1: {
					marginTop:'50px',
					marginBottom:'10px',
				},
				h2: {
					marginTop:'50px',
					marginBottom:'10px'
				}
			}
		},

		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: {
					variant: 'h3',
					component: 'span'
				}
			},
			styleOverrides: {
				content: {
					'&:after': {
						content: '" "',
						background: mainPrimaryColor,
						width: '7.5rem',
						maxWidth: '150px',
						height: '2px',
						display: 'block',
					}
				}
			}
		}
	}
})
export default theme
