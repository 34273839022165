import {Typography} from "@mui/material";
import {GlobalHeadlineUnderline} from "../../components/GlobalHeadlineUnderline.tsx";

export const TOPHeilbehandlung1200PageTextAngebot = ({tiername}:{tiername: string}) => {
	
	return <>
		<GlobalHeadlineUnderline color={'primary.main'}>
			Damit die Gesundheit von <span style={{color: '#58585a'}}>{tiername}</span> bezahlbar bleibt...
		</GlobalHeadlineUnderline>
		<Typography variant={'body1'}>
			Durch die gestiegenen Honorare der Tierärztinnen und Tierärzte ist diese Absicherung
			nun wertvoller, aber auch teurer geworden. Wir bieten Ihnen - um Beiträge zu sparen -
			jetzt hier die Möglichkeit, den Versicherungsschutz anzupassen.
		</Typography>
	</>
}
