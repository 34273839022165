import {Box, Container, Grid, Typography} from "@mui/material";
import {GlobalSection} from "./GlobalSection.tsx";
import {GlobalLink} from "./GlobalLink.tsx";
import settings from "../settings.ts";

export const GlobalFooter= ({fixed}:{fixed?:boolean}) => {


	return <Box sx={{position: fixed?'fixed':'relative', bottom:0, width:'100%'}}>
		<GlobalSection>
		<Container>
			<Grid container spacing={3}>
				<Grid item xs={12} md={9} sx={{textAlign: { xs: 'center',md: 'left'}}}>
					<GlobalLink
						href="https://www.barmenia.de/deu/bde_privat/datenschutz.xhtml"
						color={'white'}
						target={'_blank'}>
						Datenschutz
					</GlobalLink>
					<GlobalLink
						href="https://www.barmenia.de/deu/bde_privat/impressum.xhtml"
						color={'white'}
						target={'_blank'}>
						Impressum/Rechtshinweise
					</GlobalLink>
					{ settings.assekuradeur !== "Adcuri" && <GlobalLink
						href="https://www.barmenia.de/deu/bde_privat/bde_service/bde_infocenter/bde_kontakt/uebersicht.xhtml"
						color={'white'}
						target={'_blank'}>
						Kontakt
					</GlobalLink>}
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant={'h5'}>Einfach. Menschlich.</Typography>
				</Grid>
			</Grid>
		</Container>
	</GlobalSection>
	</Box>
}
