import {Link, Typography} from "@mui/material";
import {GlobalErrorBox} from "../components/GlobalErrorBox.tsx";

export const PageErrorAngebot = () => {

	return <GlobalErrorBox title={'Es tut uns sehr leid'}>
		<Typography variant={'body1'} mb={3}>Danke für Ihr Interesse am Angebot der Barmenia.</Typography>
		<Typography variant={'body1'} mb={3}>Eine Auswahl ist auf diesem Weg nicht mehr möglich. Sehr gerne machen wir Ihnen ein neues Angebot. Bitte wenden Sie sich hierfür an Ihren persönlichen Berater vor Ort oder rufen Sie einfach an. </Typography>
		<Typography variant={'body1'} mb={3}>Kennen Sie schon unser Online Kundenportal? Nein, dann klicken Sie <Link
			target={'_blank'}
			href={'https://www.barmenia.de/deu/bde_privat/bde_service/bde_selfservice/bde_meine_barmenia/uebersicht.xhtml'}>hier</Link> und
			vereinfachen Sie zukünftig Ihre Vertragsverwaltung. </Typography>
		<Typography variant={'body1'} mb={3}>Ihre Barmenia-Kundenbetreuung</Typography>
		<Typography variant={'body1'}>Barmenia Allgemeine Versicherungs-AG</Typography>
		<Typography variant={'body1'}>Barmenia-Allee 1</Typography>
		<Typography variant={'body1'}>42119 Wuppertal</Typography>
		<Typography variant={'body1'}>0202 438 3643</Typography>
		<Typography variant={'body1'}><Link href={'mailto:tierversicherung@barmenia.de'}>tierversicherung@barmenia.de</Link></Typography>
	</GlobalErrorBox>
}
