import React, {PropsWithChildren} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import {BrowserRouter} from "react-router-dom";

const AppRouter: React.FC<PropsWithChildren> = ({children}) => {
	const getBasename = (path:string) => path.substr(0, path.lastIndexOf('/'));
	return <BrowserRouter basename={getBasename(window.location.pathname)}>{children}</BrowserRouter>
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<AppRouter>
		<App />
	</AppRouter>,
)
