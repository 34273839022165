import {useCallback, useEffect, useState} from "react";
import {useApi} from "../hooks/useApi.ts";
import {TOPHeilbehandlung1200Page} from "./TOPHeilbehandlung1200Page.tsx";
import {ErmittelteAlternativen} from "../hooks/types.ts";
import {GlobalLoad} from "../components/GlobalLoad.tsx";
import {GlobalPageContainer} from "../components/GlobalPageContainer.tsx";
import {GlobalErrorAllgemein} from "../components/GlobalErrorAllgemein.tsx";
import {useQuery} from "../hooks/useQuery.ts";
import {PageErrorAngebot} from "../Generally/PageErrorAngebot.tsx";
import {PageVielenDank} from "../Generally/PageVielenDank.tsx";

type StatusType =
	"angeboteGeladen"
	| "abschlussKorrekt"
	| "restServiceFehler"
	| "antwortFehler"
	| "VorschlagAngenommen"
	| "antwortFehlerVorschlagAngenommen"



export const TOPHeilbehandlung1200Container = ({erlaubteErsteller} : {erlaubteErsteller: string}) => {

	const [laden, setLaden] = useState<boolean>(false)
	const [status, setStatus] = useState<StatusType>()
	const [ermittelteAlternativen, setErmittelteAlternativen] = useState<ErmittelteAlternativen>()
	const api = useApi()

	const marketingCode = useQuery().get("marketingCode");

	const pruefeFehlerBeiAbschluss = useCallback((res: any) => {
		if (status === 'VorschlagAngenommen') {
			if (res.nachrichten?.fehlerhaft) {
				setStatus('antwortFehlerVorschlagAngenommen')
				console.error('Fehlernachrichten', res.nachrichten.nachrichten)
			}
			else {
				setStatus('abschlussKorrekt')
			}
		}
	}, [status]);

	useEffect(() => {

		if(ermittelteAlternativen) {
			if (ermittelteAlternativen.nachrichten?.fehlerhaft) {
				setStatus('antwortFehler')
				console.error('Fehlernachrichten', ermittelteAlternativen.nachrichten.nachrichten)
			}
			else {

				if (!erlaubteErsteller.includes(ermittelteAlternativen.angebot?.ersteller ?? '')) {
					setStatus('antwortFehler')
					console.error('Fehlernachricht', 'Falscher Ersteller')
				}
				else if (ermittelteAlternativen.alternativen?.length === 0) {
					setStatus('antwortFehler')
					console.error('Fehlernachricht', 'Alternativen leer')
				}
				else {
					const beitragBestand = ermittelteAlternativen.bestand?.bruttoPraemie ?? 0.00
					const beitragAlternative = ermittelteAlternativen.alternativen?.[0].bruttoPraemie ?? 0.00
					const ersparnis = beitragBestand - beitragAlternative

					if (ersparnis <= 0) {
						setStatus('antwortFehler')
						console.error('Fehlernachricht', 'Negatives Ersparnis')
					}
					else {
						setStatus("angeboteGeladen")
					}
				}
			}
		}

	}, [ermittelteAlternativen, erlaubteErsteller])

	useEffect(() => {
		status && setLaden(false)
	}, [status])

	useEffect(() => {

		if (status === undefined) {
			setLaden(true)
			if (marketingCode) {
				api.ermittleAlternativen(marketingCode)
					.then(res => {
						setErmittelteAlternativen(res)
					})
					.catch(reason => {
						setStatus('restServiceFehler')
						console.error("Fehler beim Laden. " + reason)
					})
			}
			else {
				setStatus('restServiceFehler')
				console.error("Ohne Marketingncode ist eine Änderung nicht möglich!")
			}
		}
	}, [api, status, marketingCode])

	useEffect(() => {

		if (status === 'VorschlagAngenommen') {
			setLaden(true)
			api.waehleAlternative(ermittelteAlternativen?.alternativen?.[0].alternativenCode ?? '')
				.then(res => {
					pruefeFehlerBeiAbschluss(res)
				})
				.catch(reason => {
					setStatus('restServiceFehler')
					console.error("Fehler beim Laden. " + reason)
				})
		}
	}, [api, status, ermittelteAlternativen?.alternativen, pruefeFehlerBeiAbschluss])

	return <>
		{laden
			? <GlobalPageContainer><GlobalLoad/></GlobalPageContainer>
			: <SelectPageContent status={status} ermittelteAlternativen={ermittelteAlternativen ?? {}} setStatus={setStatus}/>
		}
	</>
}

const SelectPageContent = ({status, ermittelteAlternativen, setStatus}: {
		status?: StatusType,
		ermittelteAlternativen: ErmittelteAlternativen,
		setStatus: (val: StatusType) => void
	}
) => {
	let seitenInhalt = <></>
	switch (status) {
		case "antwortFehler":
			seitenInhalt = <GlobalPageContainer><PageErrorAngebot/></GlobalPageContainer>
			break
		case "abschlussKorrekt":
			seitenInhalt = <GlobalPageContainer><PageVielenDank/></GlobalPageContainer>
			break
		case "angeboteGeladen":
			seitenInhalt = <GlobalPageContainer mt={4} fixedFooter={false}>
				<TOPHeilbehandlung1200Page ermittelteAlternativen={ermittelteAlternativen} setStatus={setStatus}/>
			</GlobalPageContainer>
			break
		default:
			seitenInhalt = <GlobalPageContainer><GlobalErrorAllgemein/></GlobalPageContainer>
	}
	return seitenInhalt
}
