import {styled} from "@mui/material";
import {PropsWithChildren} from "react";


const MySection = styled('section')(({theme}) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
	width:'100%',
	paddingTop:theme.spacing(4),
	paddingBottom:theme.spacing(4),
}));

export type StyledSectionProps = { textAlign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start"; }
export const GlobalSection:React.FC<PropsWithChildren<StyledSectionProps>> = ({textAlign = 'center',children}) => {

	return <MySection style={{textAlign:textAlign}}>
					{children}
			</MySection>
}
