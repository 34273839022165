import {Box, Container, styled, Typography} from "@mui/material";
import React, {PropsWithChildren} from "react";
import {Clear} from "@mui/icons-material";

export type StyledErrorBoxProps = {
	title?: string
}
export const GlobalErrorBox:React.FC<PropsWithChildren<StyledErrorBoxProps>> = ({title = 'Fehler',children}) => {

	const StyledBox = styled(Box)(({theme}) => ({
		borderWidth: '3px',
		borderStyle: 'solid',
		padding: '20px',
		borderColor: theme.palette.error.main,
	}));

	return <Container><StyledBox>
			<Typography variant={'h2'} color={'error.main'} sx={{mt:0, mb:4}} ><Clear fontSize={'large'} sx={{float:'left', mt:'-2px', mr:2}} />{title}</Typography>
			{children}
		</StyledBox>
	</Container>
}
