import {Link, styled} from "@mui/material";
import {PropsWithChildren} from "react";


export type StyledLinkProps = {
	href: string;
	target?: string;
	color: string
}
export const GlobalLink: React.FC<PropsWithChildren<StyledLinkProps>> = ({
	href,
	target = '_self',
	color,
	children
}) => {


	const MyLink = styled(Link)(() => ({
		position: 'relative',
		marginRight: 20,
		'&:before': {
			width: "0%",
			bottom: '-2px',
			transition: '300ms',
			height: '2px',
			content: '""',
			position: 'absolute',
			backgroundColor: color
		},
		'&:hover': {
			opacity:1,
			'&:before': {
				width: "100%"
			},
		}
	}));

	return <MyLink href={href} target={target} underline="none" color={color}>
		{children}
	</MyLink>

}
