import {Box, Typography} from "@mui/material";
import bildTierversicherung from "../../assets/img/tierversicherung.png";

export const TOPHeilbehandlung1200PageHeader = () => {

	return <>
		<Box sx={{
			margin:'auto',
			paddingTop: {
				xs: '10px',
				md:'30px',
			},
			width: {
				xs: 250,
				md:350,
			},
		}}>
			<img src={bildTierversicherung} style={{ width: '100%'}}/>
		</Box>
		<Typography variant={'h1'}>
			DAMIT ES FÜR SIE TIERISCH EINFACH BLEIBT HABEN WIR ETWAS FÜR SIE VORBEREITET.
		</Typography>
		<Typography variant={'h6'} sx={{color: 'text.secondary', fontWeight: 'bold'}}>
			#MachenWirGern
		</Typography>
	</>
}
