import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DoneIcon from '@mui/icons-material/Done';


export type StyledListItemProps = {
	text: React.ReactNode,
	check?: boolean
	iconcolor?: 'primary.main' | 'secondary.main' | string
	textcolor?: 'primary.main' | 'secondary.main' | string
}
export const GlobalListItem = ({text, check = false, textcolor = '', iconcolor = ''}:StyledListItemProps) => {


	return <ListItem disablePadding sx={{alignItems: 'flex-start'}}>
		<ListItemIcon sx={{minWidth: '30px', marginTop:'3px'}}>{ check ?
			<DoneIcon sx={{color: iconcolor}} /> :
			<KeyboardDoubleArrowRightIcon sx={{color: iconcolor}} />}
		</ListItemIcon>
		<ListItemText primary={text}  sx={{color: textcolor}}/>
	</ListItem>
}
