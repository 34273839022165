import {CssBaseline, ThemeProvider} from "@mui/material";
import './App.css'
import theme from "./theme.ts";
import {Route, Routes} from "react-router-dom";
import {MainPageContainer} from "./main/MainPageContainer.tsx";
import {GlobalPageContainer} from "./components/GlobalPageContainer.tsx";
import {GlobalErrorAllgemein} from "./components/GlobalErrorAllgemein.tsx";
import {TOPHeilbehandlung1200Container} from "./TOP2022TierKV/TOPHeilbehandlung1200Container.tsx";

function App() {

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<Routes>
				<Route path="/" element={<MainPageContainer/>}/>
				<Route path="/top2020tierkv" element={<TOPHeilbehandlung1200Container erlaubteErsteller={'TOP2020TierKV'}/>}/>
				<Route path="/top2020opkosten" element={<TOPHeilbehandlung1200Container erlaubteErsteller={'TOP2020OPKosten'}/>}/>
				<Route path="/top2020unfallop" element={<TOPHeilbehandlung1200Container erlaubteErsteller={'TOP2020UnfallOP'}/>}/>
				<Route path="/top2022tierkv" element={<TOPHeilbehandlung1200Container erlaubteErsteller={'TOP2022TierKV'}/>}/>
				<Route path="/top2022opkosten" element={<TOPHeilbehandlung1200Container erlaubteErsteller={'TOP2022OPKosten'}/>}/>
				<Route path="/top2022unfallop" element={<TOPHeilbehandlung1200Container erlaubteErsteller={'TOP2022UnfallOP'}/>}/>
				<Route path={"*"} element={<WrongPath/>}/>
			</Routes>
		</ThemeProvider>
	)
}

function WrongPath() {
	console.error("Falscher Pfad: Die App kann mit diesem Pfad nichts anfangen")
	return <>
		<GlobalPageContainer><GlobalErrorAllgemein /></GlobalPageContainer>
	</>
}

export default App
