
import {useCallback, useMemo} from "react";
import {api, RESTApi} from "../common/api";


/**
 * Wrap the API calls with some default auth/error/loading handling
 * maps from REST-API json to internal app types.
 */
export const useApi = (): RESTApi => {
    const showError = useCallback((error: any) => {
        console.log(error);
    },[]);

    return <RESTApi>useMemo(() => {
        const a = api();
        return {
            ermittleAlternativen: (key: string) => a.ermittleAlternativen(key).catch(showError),
            waehleAlternative: (key: string) => a.waehleAlternative(key).catch(showError),
            druckeVersicherungsbedingungen: (marketingCode: string) => a.druckeVersicherungsbedingungen(marketingCode).catch(showError),
        }
    }, [showError]);
}
