import {AppConfiguration} from "./vite-env";

const lookupEnvValue = (name:string):string|undefined => {
	const val:string|undefined = (window as any)["env"][name];
	if (val && !val.startsWith("@")) return val;
	return import.meta.env["VITE_"+name];
}

const settings: AppConfiguration = {
	endpoint_url: lookupEnvValue("ENDPOINT_URL") ?? "http://www.adcuri.de.dehly1.assek-team.hvb.onesty-tech.loc/marketingdokument_adcuri",
	assekuradeur: lookupEnvValue("ASSEKURADEUR") ?? "Adcuri"
};

export default settings;
