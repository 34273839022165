import {Divider, Typography} from "@mui/material";
import {PropsWithChildren} from "react";


export type StyledHeadlineProps = {
	color?: 'primary.main' | 'secondary.main' | 'error.main' | 'warning.main' | 'info.main' | string
}
export const GlobalHeadlineUnderline: React.FC<PropsWithChildren<StyledHeadlineProps>> = ({color = '', children}) => {


	return <Typography variant={'h2'} sx={{color: color, textAlign: 'left', mt:'30px'}}>
		{children}
		<Divider color={'primary'} sx={{
			height: 2,
			width: 250,
			mb: '20px',
			mt: '10px',
			backgroundColor: color,
			borderColor: color
		}}/>
	</Typography>
}
