import {GlobalHeadlineUnderline} from "../../components/GlobalHeadlineUnderline.tsx";
import {GlobalListItem} from "../../components/GlobalListItem.tsx";


type TOP2020TierKVPageHinweisTarifProps = {
    datum: string,
    alternativTarif: string
}

export const TOPHeilbehandlung1200PageHinweisTarif = ({datum, alternativTarif}: TOP2020TierKVPageHinweisTarifProps) => {

    let datumVertragsAnderung = '-'
    if (datum !== '') {
        datumVertragsAnderung = new Date(datum).toLocaleDateString('de-DE',{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })
    }

    return <>
        <GlobalHeadlineUnderline color={'white'}>Wichtige Hinweise zu Ihrer Tarifänderung</GlobalHeadlineUnderline>
        <GlobalListItem text={'Wenn Sie den Alternativtarif ' + alternativTarif
            + ' vereinbaren, erhalten Sie von uns einen geänderten Versicherungsschein.'} iconcolor={'white'}/>
        <GlobalListItem
            text={'Es kann bei der Bearbeitung Ihrer Vertragsänderung zu manuellen Nachbearbeitungen kommen. In diesen Fällen verzögert sich der Versand des geänderten Versicherungsscheins. Das hat aber keinen Einfluss auf den Zeitpunkt, zu dem die Vertragsänderung wirksam wird - das ist der '
                + datumVertragsAnderung + '.'} iconcolor={'white'}/>
        <GlobalListItem text={'Bis zum Änderungszeitpunkt sind alle Leistung wie bisher versichert.'}
                        iconcolor={'white'}/>
    </>
}
