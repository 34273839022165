import {Typography} from "@mui/material";
import {GlobalHeadlineUnderline} from "../../components/GlobalHeadlineUnderline.tsx";
import {formatiereZahlweise} from "../../common/utils.ts";

export const TOPHeilbehandlung1200PageTextVersicherungsschutz = ({zahlweise, datum, bruttoBestand, tiername}:{
	zahlweise: string, datum: string, bruttoBestand: number, tiername: string}) => {
	let datumVertragsAnderung = '-'
	if (datum !== '') {
		datumVertragsAnderung = new Date(datum).toLocaleDateString('de-DE',{
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		})
	}

	return <>
		<GlobalHeadlineUnderline color={'primary.main'}>
			Sie möchten den Versicherungsschutz beibehalten?
		</GlobalHeadlineUnderline>
		<Typography variant={'body1'}>
			Dann brauchen Sie nichts zu tun.
			Der <b>{formatiereZahlweise(zahlweise)}e</b> Beitrag ab dem <b>{datumVertragsAnderung}</b> beträgt dann <b>{bruttoBestand.toLocaleString("de-DE", { minimumFractionDigits: 2 })} EUR</b>.

		</Typography>
		<GlobalHeadlineUnderline color={'primary.main'}>
			Möchten Sie Beiträge sparen
		</GlobalHeadlineUnderline>
		<Typography variant={'body1'} mt={2} color={'primary'}>indem Sie die Erstattung von Heilbehandlungskosten auf 1.200 EUR
			je Abrechnungsjahr begrenzen?</Typography>
		<Typography variant={'body1'}>
			Heilbehandlungskosten (für ambulante und stationäre Behandlungen) werden bis maximal 1.200 EUR je
			Abrechungsjahr erstattet (Heilbehandlungskosten siehe Abschnitt 2 unter A1 der
			Allgemeinen Versicherungsbedingungen für die Tier-Krankenversicherung).
			Unbegrenzt versichert bleiben Operationen
			(inkl. Voruntersuchung und Nachbehandlungszeitraum).
		</Typography>
		<Typography variant={'h6'} mt={2}>Genau das können Sie jetzt hier tun!</Typography>
		<Typography variant={'body1'}>
			Klicken Sie dazu einfach unser Alternativangebot unten an.
		</Typography>
		<Typography variant={'h6'} mt={2}>Ihr Vorteil:</Typography>
		<Typography variant={'body1'}>
			Sie sparen Geld und haben trotz der reduzierten Leistungen weiterhin einen wertvollen
			Versicherungs-Schutz für <b>{tiername}</b>.
		</Typography>



	</>
}
