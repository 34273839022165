import {Box} from "@mui/material";
import React, {PropsWithChildren} from "react";
import {GlobalFooter} from "./GlobalFooter.tsx";
import {GlobalAppBar} from "./GlobalAppBar.tsx";

export type StyledContainerProps = {
	mt?: string | number;
	mb?: string | number;
	fixedFooter?: boolean;
}
export const GlobalPageContainer:React.FC<PropsWithChildren<StyledContainerProps>> = (
	{mt = '88px', mb = '130px', fixedFooter = true, children}
) => {
	return <><GlobalAppBar /><Box mt={mt} mb={mb}>{children}</Box><GlobalFooter fixed={fixedFooter}/></>
}
