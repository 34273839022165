export const formatiereZahlweise = (zahlweise: string) => {
	switch (zahlweise) {
		case 'jaehrlich':
			return 'jährlich'
		case 'halbjaehrlich':
			return 'halbjährlich'
		case 'vierteljaehrlich':
			return 'vierteljährlich'
		default:
			return zahlweise
	}
}
