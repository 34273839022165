import settings from "../settings.ts";
import {ErmittelteAlternativen} from "../hooks/types.ts";

export type DownloadedFile = { fileName: string, success: boolean, blob: Blob };
export interface RESTApi {
	ermittleAlternativen: (key: string) => Promise<ErmittelteAlternativen>
	waehleAlternative: (key: string) => Promise<any>
	druckeVersicherungsbedingungen: (marketingCode: string) => Promise<DownloadedFile>
}


export function extractFilename(contentDispositionHeader?: string) {
	if (!contentDispositionHeader) {
		return "download";
	}
	const filename = contentDispositionHeader.match(/(attachment|inline);\s*filename\*?=([^']*'')?"?([^"$]+)"?/i);
	if (filename && filename?.length > 1) {
		return filename[2] ? decodeURIComponent(filename[3]) : filename[3];
	}
	return "download";
}

export function downloadFile(data: DownloadedFile, overridenFilename?: string) {
	const fileUrl = window.URL.createObjectURL(data.blob);
	const link = document.createElement('a');
	link.href = fileUrl;
	link.target = "_blank";
	link.rel="noreferrer noopener";
	link.download = overridenFilename ?? data.fileName;
	link.click();
	// For Firefox it is necessary to delay revoking the ObjectURL.
	setTimeout(() => {
		window.URL.revokeObjectURL(fileUrl);
	}, 250);
}

export const getDownloadFileData = async (res: Response):Promise<DownloadedFile> =>  ({
	fileName: extractFilename(res.headers.get('Content-Disposition') ?? undefined),
	success: res.ok,
	blob: await res.blob()
})
export const api = (): RESTApi => {
	const defaultHeader = (): HeadersInit => {
		return {
			Accept: "application/json, text/plain",
			"Access-Control-Request-Headers": "content-disposition"
		} as HeadersInit;
	}
	const customFetch = (
		url: string,
		method = "GET",
		body: any = null,
		type = "application/json",
		headers: HeadersInit = defaultHeader()
	) => {
		const options: RequestInit = {
			method,
			headers
		};
		if (body) {
			options.body = type === "application/json" ? JSON.stringify(body) : body;
			if (type) {//kein Content-Type setzen für Dateiupload aus Javascript
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				options.headers["Content-Type"] = type;
			}
		}
		return fetch(url, options)
			.then(async response => {
				if (!response.ok) {
					throw new Error(await response.text());
				}
				return response;
			})
			.catch(err => {
				throw new Error(err);
			});
	};
	const ermittleAlternativen = (key: string): Promise<any> => {
		const url = `${settings.endpoint_url}/ermittleAlternativen`;
		return customFetch(url, 'POST', {'marketingCode': key})
			.then(response => response.json())
	}
	const waehleAlternative = (key: string): Promise<any> => {
		const url = `${settings.endpoint_url}/waehleAlternative`;
		return customFetch(url, 'POST', {'alternativenCode': key})
			.then(response => response.json())
	}
	const druckeVersicherungsbedingungen = (marketingCode: string): Promise<DownloadedFile> => {
		const url = `${settings.endpoint_url}/druckeVersicherungsbedingungen`;
		return customFetch(url, 'POST', {'marketingCode': marketingCode})
			.then(response => getDownloadFileData(response))
	}

	return {
		ermittleAlternativen,
		waehleAlternative,
		druckeVersicherungsbedingungen
	};
}
