import {Container} from "@mui/material";
import {GlobalSection} from "../components/GlobalSection.tsx";
import {TOPHeilbehandlung1200PageHeader} from "./components/TOPHeilbehandlung1200PageHeader.tsx";
import {TOPHeilbehandlung1200PageTextAngebot} from "./components/TOPHeilbehandlung1200PageTextAngebot.tsx";
import {TOPHeilbehandlung1200PageTextVersicherungsschutz} from "./components/TOPHeilbehandlung1200PageTextVersicherungsschutz.tsx";
import {TOPHeilbehandlung1200PageHinweisTarif} from "./components/TOPHeilbehandlung1200PageHinweisTarif.tsx";
import {TOPHeilbehandlung1200PageTarifVergleich} from "./components/TOPHeilbehandlung1200PageTarifVergleich.tsx";
import {ErmittelteAlternativen} from "../hooks/types.ts";


type TOP2020TierKVPageProps = {
	ermittelteAlternativen: ErmittelteAlternativen,
	setStatus: (val: "VorschlagAngenommen") => void
}
export const TOPHeilbehandlung1200Page = ({ermittelteAlternativen, setStatus}: TOP2020TierKVPageProps) => {

	return <>
		<GlobalSection>
			<Container><TOPHeilbehandlung1200PageHeader/></Container>
      </GlobalSection>
		<Container component={'section'}>
			<TOPHeilbehandlung1200PageTextAngebot tiername={ermittelteAlternativen.bestand?.var4 ?? ''}/>
		</Container>
		<Container component={'section'}>
			<TOPHeilbehandlung1200PageTextVersicherungsschutz
				zahlweise={ermittelteAlternativen?.alternativen?.[0].var7 ?? ''}
            datum={ermittelteAlternativen.angebot?.referenzDatum ?? ''}
            bruttoBestand={ermittelteAlternativen.bestand?.bruttoPraemie ?? 0.00}
				tiername={ermittelteAlternativen.bestand?.var4 ?? ''}
         />
		</Container>
		<Container component={'section'}>
			<TOPHeilbehandlung1200PageTarifVergleich
				ermittelteAlternativen={ermittelteAlternativen}
				setStatus={setStatus}/>
		</Container>
		<GlobalSection>
			<Container>
             <TOPHeilbehandlung1200PageHinweisTarif
                datum={ermittelteAlternativen.angebot?.referenzDatum ?? ''}
                alternativTarif={ermittelteAlternativen.alternativen?.[0].info ?? ''}/>
         </Container>
      </GlobalSection>

	</>
}
