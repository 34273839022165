import {Box, Container, styled, Typography} from "@mui/material";
import React, {PropsWithChildren} from "react";
import {Check} from "@mui/icons-material";

export type StyledErrorBoxProps = {
	title?: string
}
export const GlobalSuccessBox:React.FC<PropsWithChildren<StyledErrorBoxProps>> = ({title = 'Die Aktion war erfolgreich',children}) => {

	const StyledBox = styled(Box)(({theme}) => ({
		borderWidth: '3px',
		borderStyle: 'solid',
		padding: '20px',
		borderColor: theme.palette.success.main,
	}));

	return <Container><StyledBox>
			<Typography variant={'h2'} color={'success.main'} sx={{mt:0, mb:4}} ><Check fontSize={'large'} sx={{float:'left', mt:'-2px', mr:2}} />{title}</Typography>
			{children}
		</StyledBox>
	</Container>
}
