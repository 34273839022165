import {GlobalListItem} from "../../components/GlobalListItem.tsx";
import {GlobalHeadlineUnderline} from "../../components/GlobalHeadlineUnderline.tsx";
import {Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, styled, Typography} from "@mui/material";
import React, {PropsWithChildren, useState} from "react";
import {Alternativen, Bestand, ErmittelteAlternativen} from "../../hooks/types.ts";
import {useQuery} from "../../hooks/useQuery.ts";
import {formatiereZahlweise} from "../../common/utils.ts";
import {useApi} from "../../hooks/useApi.ts";
import {downloadFile} from "../../common/api.ts";


const ActiveBoxReiter = styled(Box)(({theme}) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
	width: '250px',
	height: '30px',
	textAlign: 'center',
	position: 'absolute',
	left: '-3px',
	top: '-30px',
	'& p': {
		lineHeight: '30px',
	},

}));


export type TOP2020TierKVPageTarifVergleichProps = {
	ermittelteAlternativen: ErmittelteAlternativen,
	setStatus: (val: "VorschlagAngenommen") => void
}

export const TOPHeilbehandlung1200PageTarifVergleich = ({
	ermittelteAlternativen,
	setStatus
}: TOP2020TierKVPageTarifVergleichProps) => {


	const [aktiverTarif, setAktiverTarif] = useState<'aktuellerTarif' | 'neuerTarif'>('aktuellerTarif')
	const [hinweis, setHinweis] = useState(false);
	const beitragBestand = ermittelteAlternativen.bestand?.bruttoPraemie ?? 0.00
	const beitragAlternative = ermittelteAlternativen.alternativen?.[0].bruttoPraemie ?? 0.00
	const ersparnis = beitragBestand - beitragAlternative
	const zahlweiseBestand = ermittelteAlternativen.bestand?.var7 ?? '-';
	const zahlweiseAlternative = ermittelteAlternativen.alternativen?.[0]?.var7 ?? '-'

	const api = useApi()

	const akzeptiereHinweis = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHinweis(event.target.checked);
	};

	const marketingCode = useQuery().get("marketingCode");

	const getDocument = () => {
		if(marketingCode){
			api.druckeVersicherungsbedingungen(marketingCode)
				.then(res => {
					downloadFile(res, 'versicherungsbedingungen.pdf')
				})
				.catch(reason => console.error("Fehler beim Laden. " + reason))
		}

	}

	const formatiereOperationen = (leistungsTitel: string, leistungsWert: string, optionalerPraefix?: string) => {
		switch (leistungsWert) {
			case '0,00':
				return <>ohne <b>{leistungsTitel}</b></>
			case '99.999,00':
				return <><b>{leistungsTitel}</b> ohne jährliche Begrenzung</>
			default:
				return <>{optionalerPraefix ? (optionalerPraefix + ' ') : ''}<b>{leistungsTitel}</b> bis max. {leistungsWert} EUR</>
		}
	}
	const formatiereVorsorgeleistung = (leistungsTitel: string, leistungsWert: string) => {
		switch (leistungsWert) {
			case '0,00':
				return <> - ohne <b>{leistungsTitel}</b></>
			case '99.999,00':
				return <>- <b>einschließlich {leistungsTitel}</b> ohne jährliche Begrenzung</>
			default:
				return <>- <b>einschließlich {leistungsTitel}</b> bis zu {leistungsWert} EUR</>
		}
	}
	const formatiereZahnbehandlung = (leistungsTitel: string, leistungsWert: string) => {
		switch (leistungsWert) {
			case '0,00':
				return <>- ohne sonstige <b>{leistungsTitel}</b></>
			case '99.999,00':
				return <>- <b>einschließlich</b> bestimmte <b>{leistungsTitel}</b> ohne jährliche Begrenzung</>
			default:
				return <>- <b>einschließlich</b> bestimmte <b>{leistungsTitel}</b> bis zu {leistungsWert} EUR</>
		}
	}
	const formatiereHeilbehandlung = (leistungsTitel: string, ermittleAlternativen: Bestand | Alternativen, optionalerPraefix?: string) => {
		switch (ermittleAlternativen?.var9) {
			case '0,00':
				return <>ohne <b>{leistungsTitel}</b></>
			case '99.999,00':
				return <>
					<b>{leistungsTitel}</b> (für ambulante und stationäre Behandlungen) ohne jährliche Begrenzung<br/>
					{formatiereVorsorgeleistung('Vorsorgeleistungen ', ermittleAlternativen?.var1 ?? '')}<br/>
					{formatiereZahnbehandlung('Zahnbehandlungen ', ermittleAlternativen?.var2 ?? '')}
				</>
			default:
				return <>
					{optionalerPraefix ? (optionalerPraefix + ' ') : ''}<b>{leistungsTitel}</b> (für ambulante und stationäre Behandlungen) <b>bis maximal {ermittleAlternativen?.var9} EUR</b> je Abrechnungsjahr<br/>
					{formatiereVorsorgeleistung('Vorsorgeleistungen ', ermittleAlternativen?.var1 ?? '')}<br/>
					{formatiereZahnbehandlung('Zahnbehandlungen ', ermittleAlternativen?.var2 ?? '')}
				</>
		}
	}

	return <>

		<Container sx={{mt: 2, mb: 2, pl:'0 !important', pr:'0 !important'}} component={'section'}>

			<Grid container spacing={4} justifyContent={"center"}>
				<Grid item xs={12} md={6}>
					<GlobalHeadlineUnderline color={'primary.main'}>
						Ihr bisheriger Tarif
					</GlobalHeadlineUnderline>
					<MainPageTarifBox
						name={'aktuellerTarif'}
						aktiverTarif={aktiverTarif}
						setAktiverTarif={setAktiverTarif}
						zahlweise={zahlweiseBestand}
						beitrag={beitragBestand}>
						<GlobalHeadlineUnderline color={'primary.main'}>
							{ermittelteAlternativen.bestand?.info ?? '-'}
						</GlobalHeadlineUnderline>
						{ermittelteAlternativen.bestand?.var8 && <GlobalListItem
								check={true}
								text={formatiereOperationen('Operationen', ermittelteAlternativen.bestand?.var8)}
								iconcolor={'primary.main'}
						/>}
						{ermittelteAlternativen.bestand?.var9 && <GlobalListItem
								check={true}
								text={formatiereHeilbehandlung('Heilbehandlungskosten', ermittelteAlternativen.bestand)}
								iconcolor={'primary.main'}
						/>}
					</MainPageTarifBox>
				</Grid>
				<Grid item xs={12} md={6}>
					<GlobalHeadlineUnderline color={'primary.main'}>
						Unser Alternativangebot
					</GlobalHeadlineUnderline>
					<MainPageTarifBox
						name={'neuerTarif'}
						aktiverTarif={aktiverTarif}
						setAktiverTarif={setAktiverTarif}
						zahlweise={zahlweiseAlternative}
						beitrag={beitragAlternative}>
						<GlobalHeadlineUnderline color={'primary.main'}>
							{ermittelteAlternativen.alternativen?.[0].info ?? '-'}
						</GlobalHeadlineUnderline>

						{ermittelteAlternativen.alternativen?.[0].var8 && <GlobalListItem
								check={true}
								text={formatiereOperationen('Operationen', ermittelteAlternativen.alternativen?.[0].var8)}
								iconcolor={'primary.main'}
						/>}
						{ermittelteAlternativen.alternativen?.[0].var9 && <GlobalListItem
								check={true}
								text={formatiereHeilbehandlung('Heilbehandlungskosten', ermittelteAlternativen.alternativen?.[0])}
								iconcolor={'primary.main'}
						/>}
					</MainPageTarifBox></Grid>
			</Grid>
		</Container>

		<MainPageTarifSparBox ersparnis={ersparnis} name={'neuerTarif'} aktiverTarif={aktiverTarif} setAktiverTarif={setAktiverTarif}/>
		{aktiverTarif === 'neuerTarif' && <>
			<FormGroup>
				<FormControlLabel
						control={
							<Checkbox checked={hinweis} color={'primary'} onChange={akzeptiereHinweis}/>
						}
						label={
							<>
								<Typography variant={'body1'}>Bevor Sie unseren Vorschlag annehmen, bestätigen Sie bitte durch
									Anklicken
									des Ankreuzfeldes, dass Sie mit der Vereinbarung der reduzierten Leistungen einverstanden sind:<br/><br/></Typography>
								<Typography variant={'body1'} color={'primary'}>
									"Mir ist bewusst, dass die Beitragsersparnis nur dadurch möglich ist, dass ich
									mit der Annahme des Alternativangebotes auf Leistungen verzichte."<br/><br/>
								</Typography>
							</>
						}/>
			</FormGroup>
			<Typography variant={'body1'}>
				Die <DownloadLink
					component={'span'}
					onClick={getDocument}>Versicherungsbedingungen</DownloadLink>, die für Ihren Vertrag gelten, bleiben weiter
				gültig, auch wenn Sie unseren Vorschlag annehmen und den Alternativtarif mit uns vereinbaren.
				Mit dem Alternativangebot begrenzen Sie die Heilbehandlungskosten auf 1.200 EUR je Abrechnungsjahr.
				<br/>
				Hier können Sie die <DownloadLink
					component={'span'}
					onClick={getDocument}>Versicherungsbedingungen</DownloadLink> ansehen und herunterladen.
			</Typography>
			<Box sx={{textAlign: 'center'}} mb={5} mt={4}>
				<Button variant={'contained'} color={'secondary'} onClick={() => setStatus('VorschlagAngenommen')} disabled={hinweis ? false : true}>Alternativangebot annehmen</Button>
			</Box>
		</>}
	</>
}

const DownloadLink = styled(Box)(({theme}) => ({
	color: theme.palette.primary.main,
	textDecoration:'underline',
	cursor:'pointer',
	':hover': {
		textDecoration:'none',
	}
}));


export type StyledTarifBoxProps = {
	name: 'aktuellerTarif' | 'neuerTarif',
	aktiverTarif: 'aktuellerTarif' | 'neuerTarif',
	setAktiverTarif: (tarif: 'aktuellerTarif' | 'neuerTarif') => void,
	beitrag: number
	zahlweise: string
}
const MainPageTarifBox: React.FC<PropsWithChildren<StyledTarifBoxProps>> = (
	{
		name,
		aktiverTarif,
		setAktiverTarif,
		beitrag,
		zahlweise,
		children
	}) => {

	const active = aktiverTarif === name;

	const TarifBox = styled(Box)(({theme}) => ({
		cursor: active ? 'inherit' : 'pointer',
		position: 'relative',
		backgroundColor: active ? 'white' : '#eeeeee',
		borderWidth: '3px',
		borderStyle: 'solid',
		padding: '0px 20px 10px 20px',
		borderImage: active ? 'linear-gradient(' + theme.palette.primary.main
			+ ',#ffffff00) 1 40% / 1 / 0 stretch' : '',
		borderColor: active ? '' : '#eeeeee',
		boxShadow: active ? 'rgba(0, 0, 0, 0.2) 0px 0px 20px 0px' : 'none',
	}));

	return <>
		<TarifBox sx={{mb: 4}} onClick={() => setAktiverTarif(name)}>
			{active && <ActiveBoxReiter><Typography variant={'body1'}><b>{name === 'aktuellerTarif' ? 'Aktueller'
				: 'Ausgewählter'} Tarif</b></Typography></ActiveBoxReiter>}
			{children}
			<Typography variant={'body1'} sx={{mt: 3, width: '200px',}}><b>Ihr Beitrag</b> ({formatiereZahlweise(zahlweise)})</Typography>
			<BeitragBox beitrag={beitrag}/>
		</TarifBox>
	</>
}

const MainPageTarifSparBox = ({ersparnis, setAktiverTarif, name, aktiverTarif}: { ersparnis: number, setAktiverTarif: (tarif: "aktuellerTarif" | "neuerTarif") => void, name: "aktuellerTarif" | "neuerTarif", aktiverTarif: string}) => {

	const SparBox = styled(Box)(({theme}) => ({
		position: 'relative',
		backgroundColor: '#FFF',
		borderWidth: '3px',
		borderStyle: 'solid',
		padding: '0px 20px 10px 20px',
		borderColor: theme.palette.primary.contrastText,
		boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 20px 0px',
		cursor: 'pointer',

		borderImage: aktiverTarif === name ? 'linear-gradient(' + theme.palette.primary.main
			+ ',#ffffff00) 1 40% / 1 / 0 stretch':'',
		':hover': {
			borderImage: 'linear-gradient(' + theme.palette.primary.main
				+ ',#ffffff00) 1 40% / 1 / 0 stretch',
		}
	}));

	const isNegativZahl = ersparnis <= 0

	return <>
		<SparBox sx={{mb: 5}} onClick={() => setAktiverTarif(name)}>
			<Grid sx={{width: '100%'}} container mt={4} mb={2}>
				<Typography variant={'h3'} color={'primary'} sx={{width: '100%'}}>{aktiverTarif !== name ? 'Hier klicken und '+ (isNegativZahl ?'abschließen:':'Sie sparen:') : (!isNegativZahl ? 'Sie sparen:' : '')}</Typography>
				<Grid item xs={9} md={9}></Grid>
				<Grid item xs={3} md={3}>
					<BeitragBoxSparen beitrag={ersparnis}/>
				</Grid>
			</Grid>
		</SparBox>
	</>
}

const BeitragBox = ({beitrag}: { beitrag: number }) => {

	const beitragAsString = beitrag.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
	let euroWert, centWert;
	if (beitragAsString) {
		euroWert = beitragAsString.split(',')[0]
		centWert = beitragAsString.split(',')[1]
	}

	const StyledBeitragBox = styled(Box)(() => ({
		fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
		width: '100%',
		height: '55px',
		display: 'flex',
		justifyContent: 'end',
		'& .euroWert': {
			fontSize: '54px',
			lineHeight: '54px',
			fontWeight: 'bold',
			float: 'left'
		},
		'& .centWert': {
			marginTop: '10px',
			paddingLeft: '6px',
			lineHeight: '24px',
			fontSize: '24px',
			fontWeight: 'bold',
		},
		'& .euro': {
			paddingLeft: '6px',
			lineHeight: '14px',
			fontSize: '14px',
			fontWeight: 'bold'
		}
	}));


	return <StyledBeitragBox>
		<Box className={'euroWert'}>
			{euroWert},
		</Box>
		<Box sx={{width: '41px', float: 'left'}}>
			<Grid container>
				<Grid item xs={12} className={'centWert'}>
					{centWert}
				</Grid>
				<Grid item xs={12} className={'euro'}>
					EUR
				</Grid>
			</Grid>
		</Box>
	</StyledBeitragBox>
}

const BeitragBoxSparen = ({beitrag}: { beitrag: number }) => {

	const beitragAsString = beitrag.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
	let euroWert, centWert;
	if (beitragAsString) {
		euroWert = beitragAsString.split(',')[0]
		centWert = beitragAsString.split(',')[1]
	}

	const StyledBeitragBox = styled(Box)(({theme}) => ({
		fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',

		color: theme.palette.primary.main,
		width: '100%',
		height: '55px',
		display: 'flex',
		justifyContent: 'end',
		'& .euroWert': {
			fontSize: '54px',
			lineHeight: '54px',
			fontWeight: 'bold',
			float: 'left'
		},
		'& .centWert': {
			marginTop: '10px',
			paddingLeft: '6px',
			lineHeight: '24px',
			fontSize: '24px',
			fontWeight: 'bold',
		},
		'& .euro': {
			paddingLeft: '6px',
			lineHeight: '14px',
			fontSize: '14px',
			fontWeight: 'bold'
		}
	}));

	return <StyledBeitragBox>
		<Box className={'euroWert'}>
			{euroWert},
		</Box>
		<Box sx={{width: '41px', float: 'left'}}>
			<Grid container>
				<Grid item xs={12} className={'centWert'}>
					{centWert}
				</Grid>
				<Grid item xs={12} className={'euro'}>
					EUR
				</Grid>
			</Grid>
		</Box>
	</StyledBeitragBox>
}
