import {AppBar, Container, Toolbar} from "@mui/material";
import logo from "../assets/img/barmeniaLogo.svg";

export const GlobalAppBar = () => {

	return <AppBar position="fixed" sx={{height:64, boxSizing: 'border-box'}}>
		<Toolbar sx={{p: 0}}>
			<Container>
				<img src={logo} height={54} style={{marginTop: '6px'}}/>
			</Container>
		</Toolbar>
	</AppBar>
}
